.coinLogo {
    width: 265px;
    height: 265px;
    position: absolute;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
}
.claimable {
    font-size: 40px;
    font-weight: 900;   
}

.rewards {
    font-size: 16px;
}

.span {
    font-weight: normal;
}


.balance {
    font-size: 30px;
    font-weight: bolder;
}
.balance-amount {
    font-size: 20px;
    font-weight: 500;
}
.token-price {
    font-size: 40px;
    font-weight: bolder;
}
.graphBtnMobile {
    opacity: 0;
}
@media screen and (max-width: 765px) {
    .claimable {
        font-size: 34px;
        font-weight: 700;
    }
    .balance {
        font-size: 20px;
        font-weight: bold;
    }
    .balance-amount {
        font-size: 16px;
        font-weight: 400;
    }
    .token-price {
        font-size: 22px;
        font-weight: 700;
    }
    .graphBtnMobile {
        opacity: 1;
    }
    .graphBtn {
        opacity: 0;
    }
}


/* 
Token Name: A-NATION
Symbol: ANATION
Circulating Supply: 651,101,935,140,554
Blockchain: BSC - BINANCE SMART CHAIN (BEP20)
ANATION Contract: 
0xE52eC5001b31Afb845cb325c14d1764065c6Ca0c
 */