@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --tablet: 760px;
  --mobile: 540px
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Orbitron', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-orbitation {
  font-family: 'Orbitron', sans-serif;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.font-bold {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-white {
  color: #fff;
}

.btn {
  color: #fff;
  background: none;
  padding: 12px 20px;
  border: 3px solid #fff;
  border-radius: 50px;
  cursor: pointer
}

.activeBtn {

  background-color: #fff;
  color: #1D00C6;
}

.btn:hover {
  background-color: #fff;
  color: #1D00C6;
}

input:focus {
  outline: none;
}

.sectionMargin {
  margin-top: 20px;
}

.bg-main {
  /* background: linear-gradient(156deg, rgba(24,1,62,1) 24%, rgba(24,43,85,1) 42%, rgba(24,1,62,1) 52%, rgba(24,43,85,1) 64%, rgba(24,1,62,1) 84%) */
  background-color: rgb(82, 0, 235);
  background-color: #1d00c6;
  /* background-image: url('./assets/bg/bg\ transparrent.png'); */
  background-repeat: round;
}

.bg-header {
  background: #12121233;
}

.bg-box {
  background: rgb(17, 0, 41);
}

.bg-light-box {
  background: #ff381b;
}

.bg-btn {
  background: #170038;
}

.bg-bnb-icon {
  background: #ca9808;
}

.bg-light-btn {
  background: #7cb5ec;
}

.bg-none {
  background: none;
}

.bg-input {
  background: rgba(17, 0, 41, 0.4);
}

.bg-secondary {
  background: rgba(36, 3, 82, 0.4);
}

.container-div {
  padding: 0 10%;
}

.main-heading {
  font-size: 40px;
  font-weight: 900;
}

.text-heading {
  color: #29b7dd;
}

.text-normal {
  color: #6e6890
}

.navItem {
  text-transform: uppercase;
  font-size: large;
  cursor: 16px;
  cursor: pointer;
}

.navItem:hover {
  color: #BAC712;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.border-strong {
  border: 2px solid rgb(244, 81, 56);
}

.text-strong {
  color: rgb(244, 81, 56);
}

.bg-strong {
  background-color: rgb(244, 81, 56);
}

.backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 110;
}

.fixed-center {
  transform: translateX(-50%);
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}

@media(min-width:1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}

#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

.bg-banner {
  background-image: url('./assets/bannerantn.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 90vh; */
}

.bg-124 {
  background-image: url('./assets/124.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-123 {
  background-image: url('./assets/123.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;


}

.bg-roadmap {
  background-image: url('./assets/roadmap.png');
  background-color: #350f6a;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-details-box {
  background: rgba(34, 2, 75, 255);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.form-item {
  background: #19003e;
  border: 1px solid #350f6a;
  border-radius: 8px;
  outline: 0;
  padding: 10px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}

.flip-coin {
  animation: 7s linear infinite forwards flip;
}

@font-face {
  font-family: anation;
  src: url('./assets/fonts/armalite-rifle.regular.ttf');
}

.anation {
  font-family: anation;
  color: #f45138;
  font-size: 75px;
  /* position: absolute;
  top: 0;
  left: 50%; */
}

.timer {
  box-shadow: 0 0 20px rgb(244 81 56 / 50%);
  border: 2px solid #f45138;
  transform: translateX(-50%);
}

.slider {
  transform: translateX(10px);
  /* transition: all 0s ease 0s;
  animation-timing-function: linear;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-delay: 2s; */
}

.slow {
  animation-timing-function: ease-in;
  transition-duration: 0.25s;
  /* animation: move 3s ease-out forwards infinite;
  animation-play-state: paused; */
}

@keyframes move {
  0% {
    transform: translateX(500px);
  }
}

.overflow-hidded {
  overflow: hidden;
}

@keyframes animate {
  0% {
    left: -10px;
  }

  25% {
    left: -20px;
  }

  50% {
    left: -30px;
  }

  75% {
    left: -40px;
  }

  100% {
    left: -50px;
  }
}

.text-yellow-800 {
  color: #f45138;
}

.header {
  color: #000;
}

.container {
  background-color: rgba(56, 56, 56, 0.52);
  border-radius: 10px;
  padding: 20px 50px;
}

/* Common */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.block {
  display: block;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}


.items-center {
  align-items: center;
}

.gap-x-4 {
  column-gap: 20px;
}

.gap-y-4 {
  row-gap: 20px;
}

.relative {
  position: relative;
}


#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}

@media(min-width:1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}

#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

.social-icon {
  width: 52px;
  height: 52px;
}

.word-wrap {
  word-break: break-all;
}

.section-one-left-div {
  width: 30%;
}

.section-one-right-div {
  width: 70%;
  color: #fff;
}

.reward-container {
  padding: 350px 0 130px 0;
}

.info-wrapper {
  flex-wrap: wrap;
}

.section-two-seprator {
  width: 2px;
  background: #fff;
}

.section-two-wrapper {
  width: 50%;
}

.volume-filter-wrapper-mobile {
  display: none;
}

.filterBtn {
  width: 120px;
}

.holder-input {
  width: 480px;
}

.text-12 {
  font-size: 12px;
}

.button-group-mobile {
  display: none;
}

.text-center {
  text-align: center;
}

.footer-mobile {
  display: none;
}

.section-one-container {
  min-width: 245px;
}

.header-mobile {
  /* min-width: 35px; */
  display: none;
}

.side-nav {
  position: fixed;
  left: 0;
  top: 120px;
  height: 100%;
  background-color: #5000EA;
  padding: 20px;
}

.link {
  color: #fff;
  text-decoration: none;
}

.side-nav-items-wrapper {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.hamburger {
  cursor: pointer;
}

.token-details-label {
  font-size: 30px;
  font-weight: bolder;
  margin-top: 50px;
}
.info-wrapper {
  column-gap: 20px;
  font-size: 16px;
}
.reward-wrapper {
  margin-top: 20px;
}
@media screen and (max-width: 1280px) {
  .section-one-container {
    flex-direction: column;
    row-gap: 20px;
  }

  .section-one-left-div {
    width: 100%;
    padding: 150px 0 50px 0;
  }


  .reward-wrapper {
    padding: 10px 50px;
    margin-top: 20px;
  }

  .section-one-right-div {
    width: 100%;
  }

  .header-big {
    display: none;
  }

  .header-mobile {
    display: flex;
  }

  /* .reward-container {
    padding: 250px 0 130px 0;
  } */
}

@media screen and (max-width: 1000px) {
  .token-details-label {
    font-size: 30px;
    text-align: center;
  }

  .fee-charts-wrapper {
    flex-direction: column;
  }

  .chart-labels {
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
  }

  .contract-address {
    flex-direction: column;
    text-align: center;
  }

  .info-wrapper {
    justify-content: center;
  }


  .section-tow-container {
    flex-direction: column;
    row-gap: 10px;
  }

  .section-two-seprator {
    height: 2px;
    width: 100%;
  }

  .section-two-wrapper {
    width: 100%;
  }

  .volum-label {
    text-align: center;
  }

  .volume-filter-wrapper-desktop {
    display: none;
  }

  .volume-filter-wrapper-mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .anation-amount-label {
    text-align: center;
    /* width: 100%; */
  }

  .holder-input {
    width: 95%;
  }

  .input-wrapper {
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }


  .feeBtnMobile,
  .chartBtnMobile {
    width: 50%;
  }

  .button-group-mobile {
    display: flex;
  }

  .button-group-desktop {
    display: none;
  }

  .footer-mobile {
    display: flex;
  }

  .footer-desktop {
    display: none;
  }

  .res-reward-label {
    text-align: center;
  }
}

@media screen and (max-width: 765px) {
  .reward-wrapper {
    flex-direction: column;
    margin-top: 20px;
  }

  .balance-wrapper {
    flex-direction: column;
  }

  .filterBtn {
    width: 90px;
    font-size: 10px;
  }
  .info-wrapper {
    column-gap: 5px;
    align-items: center;
  }
  /* .social-icon {
    width: 30px;
    height: 30px;
  } */
}

/* .custom-social {
  background: none;
  border: 3px solid white;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  min-width: 20px;
  margin-left: 25px;
  min-height: px;
  padding: 12px
} */